import {createContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {getCurrentPageSettings} from './pageSettings';

export const PageContext = createContext({ pageMessage: '', pageErrorMessage: '' });

export const PageProvider = ({ children }) => {
  const location = useLocation();
  const [pageSettings, setPageSettings] = useState(getCurrentPageSettings(location));
  const [pageMessage, setPageMessage] = useState('');
  const [pageMessages, setPageMessages] = useState([]);
  const [pageErrorMessage, setPageErrorMessage] = useState('');
  const [pageErrorMessages, setPageErrorMessages] = useState([]);

  // When the authenticated user changes, load a new user record and update session state variables
  useEffect(() => {
    const currentPageSettings = getCurrentPageSettings(location);
    setPageSettings(currentPageSettings);
  }, [location]);

  return (
    <PageContext.Provider value={{
      pageSettings,
      pageMessage,
      setPageMessage,
      pageMessages,
      setPageMessages,
      pageErrorMessage,
      setPageErrorMessage,
      pageErrorMessages,
      setPageErrorMessages
    }}>
      {children}
    </PageContext.Provider>
  );
};
