import React, {useContext, useEffect} from 'react';
import SectionBar from '../common/Layout/SectionBar';
import {Box} from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import { Alert, Typography } from '@mui/material';
import {useLocation} from 'react-router-dom';
import {PageContext} from '../../lib/pageContext';
import Sidebar from '../common/Layout/Sidebar';

const Page = (props) => {
  const {
    pageSettings,
    pageMessage,
    setPageMessage,
    setPageMessages,
    pageMessages,
    pageErrorMessage,
    setPageErrorMessage,
    setPageErrorMessages,
  } = useContext(PageContext);

  const { title, sectionName, pageName, hideNav, navPhoto, contentGrid = true, children } = {
    ...pageSettings,
    ...props,
  };

  const location = useLocation();

  useEffect(() => {
    setPageMessages([]);
    setPageErrorMessages([]);
    if (location.state?.error) {
      setPageErrorMessage(location.state.error);
    } else {
      setPageErrorMessage('');
    }
    if (location.state?.message) {
      setPageMessage(location.state.message);
    } else {
      setPageMessage('');
    }
  }, [location, setPageErrorMessage, setPageErrorMessages, setPageMessage, setPageMessages]);

  const errorArray = Array.isArray(pageErrorMessage) ? pageErrorMessage : pageErrorMessage ? [pageErrorMessage] : [];

  return (
    <Box className="mui">
      <SectionBar
        sectionName={sectionName}
        pageName={pageName}
      />
      {contentGrid && (
      <Box className={'outer-grid'} sx={{ padding: '32px 24px' }}>
        <Grid container gap={3} columns={12} wrap={'nowrap'}>
          <Box
            component={Grid}
            md={3}
            lg={2}
            display={{ xs: 'none', sm: 'none', md: 'block' }}
            className={'sidebar-container'}
          >
            { !hideNav && (
              <Sidebar photo={navPhoto} />
            ) }
          </Box>
          <Grid className={'page-content'} xs={12} sm={12} md={9} lg={10}>
            <Typography variant="h4" className={'page-title'}>{title}</Typography>
            {pageMessages.length > 0 && pageMessages.map((message, i) => (
              <Alert severity="success" sx={{ marginBottom: '10px' }} key={`message-${i}`}>{message}</Alert>
            ))}
            {!!pageMessage &&
              <Alert severity="success" sx={{ marginBottom: '10px' }}>{pageMessage}</Alert>
            }
            {!!errorArray.length && (
              <>
                {errorArray.map((msg, i) =>
                  <Alert severity="error" sx={{ marginBottom: '10px' }} key={`error-${i}`}>{msg}</Alert>
                )}
              </>
            )}

            {/* NOTE: Columns change depending on whether, or not we're showing the nav. This affects the page content components. */}
            <>
              { children }
            </>
          </Grid>
        </Grid>
      </Box>
      )}
      {!contentGrid && children}
    </Box>
  );
};

export default Page;
