import React, {useContext} from 'react';
import {List, ListItem, ListItemButton, ListItemText, styled} from '@mui/material';
import {Link} from 'react-router-dom';
import UserContext from '../../../lib/userContext';

const NavMenu = ({ showHome = false, listStyles = {}, onLinkClick = null }) => {
  const { isUser, isAdmin, isLoggedIn, signOut } = useContext(UserContext);

  const NavLink = ({ to, text, onClick }) => (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={to || ''} onClick={() => {
        if (onClick) onClick();
        if (onLinkClick) onLinkClick();
      }}>
        <ListItemText primary={text} sx={{ color: 'ilblue.main' }}/>
      </ListItemButton>
    </ListItem>
  );

  const NavList = styled(List)(listStyles);

  return (
    <>
      <NavList dense={true} variant="nav" className={'nav'}>
        {showHome && <NavLink to={'/'} text={'Home'} />}
        {isLoggedIn() && (
          <NavLink to="/member/profile" text="My Profile"/>
        )}
        {(isUser() && !isAdmin()) && (
          <>
            <NavLink to="/member/activities" text="My Activities"/>
            <NavLink to="/checkin" text="Check In"/>
          </>
        )}
        {isLoggedIn() && (
          <NavLink to="/member/password" text="Change Password"/>
        )}
        {!isLoggedIn() && (
          <>
            <NavLink to="/signup" text="Sign Up" />
            <NavLink to="/login" text="Log In" />
            <NavLink to="/checkin" text="Check In" />
          </>
        )}
      </NavList>
      {/*<Divider className={'nav'} />*/}
      {isAdmin() && (
        <NavList variant="nav" dense={true} className={'nav'}>
          <NavLink to="/events/create" text="Create Event"/>
          <NavLink to="/events/current" text="Current Event"/>
          <NavLink to="/member/password" text="Change Password"/>
        </NavList>
      )}
      {isLoggedIn() && (
        <NavList variant="nav" dense={true} className={'nav'}>
          <NavLink onClick={() => signOut()} text="Sign Out"/>
        </NavList>
      )}
    </>
  );
};

export default NavMenu;
