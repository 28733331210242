import React, {useContext, useState} from 'react';
import CheckinForm from './CheckinForm';
import {PageContext} from '../../../lib/pageContext';
import Page from '../Page';
import { scrollToAlert } from '../../../lib/utils';

const Checkin = (props) => {
  const { setPageMessage, setPageErrorMessage } = useContext(PageContext);
  const [photo, setPhoto] = useState('/images/checkin/left_sidebar_photo-beforecheckin.jpg');

  const onSuccess = () => {
    setPageMessage('You are checked in! Thank you for attending.');
    setPhoto('/images/checkin/left_sidebar_photo-aftercheckin.jpg');
    scrollToAlert();
  };

  const onError = (message) => {
    setPageErrorMessage(message);
    scrollToAlert();
    // Clear page message after 5 seconds
    setTimeout(() => setPageErrorMessage(null), 5000);
  }

  return (
    <>
      <Page
        title="Event Check In"
        navPhoto={{ image: photo }}
      >
        <CheckinForm
          onSuccess={onSuccess}
          onError={onError}
        />
      </Page>
    </>
  )
}


export default Checkin
