import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import {CircularProgress} from '@mui/material';

import {Auth} from 'aws-amplify';

const Verify = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [verifying, setVerifying] = useState(true);

  useEffect(() => {
    const username = searchParams.get('user');
    const code = searchParams.get('code');

    if (!username || !code) {
      setVerifying(false);
      navigate('/login', {
        state: { error: 'Invalid verification link.' }
      });
    } else {
      Auth.confirmSignUp(username, code)
        .then((result) => {
          // console.log('signup confirmed result', result);
          setVerifying(false);
          navigate('/login', {
            state: { message: 'Your account has been verified. Please sign in.' }
          });
        })
        .catch((err) => {
          // console.log('error', err);
          setVerifying(false);
          if (err.code === 'ExpiredCodeException' || err.message === 'Invalid verification code provided, please try again.') {
            navigate('/login', {
              state: { error: 'The verification link is expired.' }
            });
          } else if (err.message === 'User cannot be confirmed. Current status is CONFIRMED') {
            navigate('/login', {
              state: { message: 'Your account has already been verified. Please sign in.' }
            });
          } else {
            navigate('/login', {
              state: { error: err.message }
            });
          }
        });
    }
  }, [searchParams, navigate]);

  return (
    <Grid container spacing={3}>
      {verifying && (
        <>
          <Grid xs={12} display="flex" justifyContent="center">
            Please wait while your account is verified. Afterwards, you will be redirected to the login page.
          </Grid>
          <Grid xs={12} display="flex" justifyContent="center">
            <CircularProgress/>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Verify;
