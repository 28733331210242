import React from 'react';
import {Route, Routes} from 'react-router-dom';
import HomePage from './Home/Home';
import SignUp from './SignUp/SignUp';
import Login from './Login/Login';
import OldCurrentEvent from './Events/OldCurrentEvent';
import Activities from './Member/Activities';
import CreateEvent from './Events/CreateEvent';
import Profile from './Member/Profile';
import Checkin from './Checkin/Checkin';
import EventDetails from './Events/EventDetails';
import ChangePassword from './ChangePassword/ChangePassword';
import RequireAuth from '../common/RequireAuth';
import LoginForm from './Login/LoginForm';
import ResetPassword from './Login/ResetPassword';
import Verify from './Login/Verify';

const PageRoutes = (props) => {
  return (
    <Routes
      sectionName={props.sectionName}
    >
      <Route path="/" element={<HomePage />}/>

      { /** UTIL **/ }
      <Route path="/signup" element={<SignUp />}/>
      <Route path="/login" element={<Login />}>
        <Route index element={<LoginForm />}/>
        <Route path="password" element={<ResetPassword />}/>
        <Route path="verify" element={<Verify />}/>
      </Route>
      <Route path="/checkin" element={
        <Checkin />
      }/>

      { /** MEMBER **/ }
      <Route path="/member/profile" element={
        <RequireAuth>
          <Profile
            title="My Profile"
            sectionName="member"
            pageName={props.pageName}
          />
        </RequireAuth>
      }/>
      <Route path="/member/password" element={
        <RequireAuth>
          <ChangePassword />
        </RequireAuth>
      }/>
      <Route path="/member/activities" element={
        <RequireAuth>
          <Activities />
        </RequireAuth>
      }/>

      { /** EVENTS **/ }
      <Route path="/events" element={
        <RequireAuth>
          <OldCurrentEvent
            title="Events"
            sectionName={props.sectionName}
            pageName={props.pageName}
          />
        </RequireAuth>
      }/>
      <Route path="/events/current" element={
        <RequireAuth>
          <OldCurrentEvent
            title="Current Event"
            sectionName="events"
            pageName={props.pageName}
          />
        </RequireAuth>
      }/>
      <Route path="/events/create" element={
        <RequireAuth>
          <CreateEvent />
        </RequireAuth>
      }/>
      <Route path="/events/details" element={
        <RequireAuth>
          <EventDetails />
        </RequireAuth>
      }/>

      { /** INEP **/ }
      <Route path="/:inep" element={<CreateEvent />} />
    </Routes>
  );
};

export default PageRoutes;
