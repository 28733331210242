import { forwardRef } from "react"
import { useController } from "react-hook-form"
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup, Typography,
  useTheme
} from '@mui/material';
import { useFormError } from "react-hook-form-mui"

const RadioButtonGroup = forwardRef(function RadioButtonGroup(props, ref) {
  const {
    helperText,
    options,
    label,
    name,
    parseError,
    labelKey = "label",
    valueKey = "id",
    required,
    emptyOptionLabel,
    returnObject,
    row,
    control,
    type,
    labelProps,
    disabled,
    formLabelProps,
    helperTextProps,
    description,
    ...rest
  } = props
  const theme = useTheme()

  const errorMsgFn = useFormError()
  const customErrorFn = parseError || errorMsgFn

  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    rules: required ? { required: "This field is required" } : undefined,
    disabled,
    control
  })

  const renderHelperText = error
    ? typeof customErrorFn === "function"
      ? customErrorFn(error)
      : error.message
    : helperText

  const onRadioChange = event => {
    const radioValue = event.target.value
    const returnValue = returnObject
      ? options.find(items => items[valueKey] === radioValue)
      : radioValue
    field.onChange(returnValue)
    if (typeof rest.onChange === "function") {
      rest.onChange(returnValue)
    }
  }

  return (
    <FormControl error={!!error} ref={ref}>
      {label && (
        <FormLabel {...formLabelProps} required={required} error={!!error}>
          {label}
        </FormLabel>
      )}
      {description && <Typography variant="body2" component="div">{description(error)}</Typography>}
      <RadioGroup
        onChange={onRadioChange}
        name={name}
        row={row}
        value={field.value || ""}
      >
        {emptyOptionLabel && (
          <FormControlLabel
            {...labelProps}
            control={
              <Radio
                sx={{
                  color: error ? theme.palette.error.main : undefined
                }}
                checked={!field.value}
              />
            }
            label={emptyOptionLabel}
            value=""
          />
        )}
        {options.map(option => {
          const optionKey = option[valueKey]
          if (!optionKey) {
            console.error(
              `CheckboxButtonGroup: valueKey ${valueKey} does not exist on option`,
              option
            )
          }
          let val = returnObject ? field.value?.[valueKey] : field.value
          if (type === "number") {
            val = Number(val)
          }
          const isChecked = val === optionKey
          return (
            <FormControlLabel
              {...labelProps}
              control={
                <Radio
                  sx={{
                    color: error ? theme.palette.error.main : undefined
                  }}
                  disabled={disabled}
                  checked={isChecked}
                />
              }
              value={optionKey}
              label={option[labelKey]}
              key={optionKey}
            />
          )
        })}
      </RadioGroup>
      {renderHelperText && <FormHelperText {...helperTextProps}>{renderHelperText}</FormHelperText>}
    </FormControl>
  )
})
RadioButtonGroup.displayName = "RadioButtonGroup"

export default RadioButtonGroup
