import React, {useContext} from 'react';
import ProfileForm from './ProfileForm';
import userContext from '../../../lib/userContext';
import Page from '../Page';

const Profile = () => {
  const { userState: { userRecord } } = useContext(userContext);

  return (
    <Page
      title="My Profile"
      sectionName="member"
      navPhoto={{ image: '/images/profile/left-sidebar.png' }}
    >
      {userRecord &&
        <ProfileForm />
      }
    </Page>
  );
};

// export default withAuthenticator(Login)
export default Profile;
