import React, { useContext, useEffect, useRef, useState } from 'react';
import {PageContext} from '../../../lib/pageContext';
import Page from '../Page';
import Grid from '@mui/material/Unstable_Grid2';
import {DateTimePickerElement, FormContainer, SelectElement, TextFieldElement} from 'react-hook-form-mui';
import {useForm} from 'react-hook-form';
import {API, graphqlOperation} from 'aws-amplify';
import {createEvent} from '../../../graphql/mutations';
import UserContext from '../../../lib/userContext';
import {eventByShortCode} from '../../../graphql/queries';
import {Box} from '@mui/system';
import {states} from '../../../lib/constants';
import {Alert, Button, Typography} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { generateQRCode, generateShortCode, notzUTCString, scrollToAlert, scrollToTitle } from '../../../lib/utils';
import {getAllCurriculums, getBoothTopics} from './createEventFormFunctions';
import {useNavigate, useParams} from 'react-router-dom';

const INEP_KEY = process.env.REACT_APP_INEP_EVENT_PARAM;

const CreateEvent = () => {
  const { signInGuest, signOutGuest, isAdmin } = useContext(UserContext);
  const {
    setPageMessage,
    setPageErrorMessage,
  } = useContext(PageContext);

  const [availableCurriculum, setAvailableCurriculum] = useState(null);
  const [availableTopics, setAvailableTopics] = useState(null);
  const [lessons, setLessons] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const [wasSubmitting, setWasSubmitting] = useState(false);

  const routeParams = useParams();
  const navigate = useNavigate();

  const inep = routeParams?.inep === INEP_KEY;

  // Redirect the user to the home page if the INEP code is missing.
  if (routeParams?.inep !== undefined && routeParams?.inep !== INEP_KEY) {
    console.log('Missing INEP key. Redirecting to home...');
    navigate('/');
  }

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: {
      state: 'IL',
      points: 0,
      event_type: '',
      description: 'Event description coming in a future phase.',
      event_start: moment().add(1, 'hour').minutes(0),
      event_end: moment().add(2, 'hour').minutes(0),
    }
  });

  const eventTypeWatch = watch('event_type');

  useEffect(() => {
    const errorValues = Object.values(errors);
    const [missingRequired, otherErrors] = errorValues.reduce((acc, error) => {
      if (error.type === 'required') {
        acc[0] = true;
      } else {
        if (!acc[1].includes(error.message)) {
          acc[1].push(error.message);
        }
      }
      return acc;
    }, [false, []]);

    // Here tracks if the form was submitted on the last render (meaning submit was clicked and validation is occurring)
    if (isSubmitting) {
      setWasSubmitting(true);
    } else if (wasSubmitting) {
      setWasSubmitting(false);
    }

    if (missingRequired || !!otherErrors.length) {
      // Only show the alert if the form was actually submitted via the button
      if (wasSubmitting) {
        const messages = [];
        if (missingRequired) {
          messages.push('Please fill out all required (*) fields.');
        }
        if (!!otherErrors.length) {
          messages.push(...otherErrors)
        }
        if (messages.length) {
          setPageErrorMessage(messages);
        }
        scrollToAlert();
      }
    } else {
      setPageErrorMessage(null);
    }
  }, [errors, isSubmitting, setPageErrorMessage, wasSubmitting]);

  useEffect(() => {
    async function fetchCurricula() {
      const curriculum = await getAllCurriculums();
      setAvailableCurriculum(curriculum);
    }

    fetchCurricula().then().catch((err) => console.error(err));
    const fetchTopics = async () => {
      const topics = await getBoothTopics();
      setAvailableTopics(topics);
    };
    fetchTopics().then().catch((err) => console.error(err));
  }, []);

  const shortCodeIsUnique = async (shortCode) => {
    const events = await API.graphql(graphqlOperation(eventByShortCode, { short_code: shortCode }));
    return events.data.eventByShortCode.items.length === 0;
  };

  const generateUniqueShortCode = async () => {
    let shortCode = generateShortCode();
    const unique = await shortCodeIsUnique(shortCode);
    if (unique) {
      return shortCode;
    } else {
      return await generateUniqueShortCode();
    }
  };

  const resetForm = () => {
    // Reset form hook
    reset();
    // Clear page message after 5 seconds
    setTimeout(() => setPageMessage(''), 5000);
  };

  const onSubmit = async (data) => {
    setPageErrorMessage(null);
    setSubmitting(true);

    // Sign in as guest if using INEP link
    if (inep) await signInGuest();

    // if (data.event_type === 'BOOTH') {
    //   data.pears_program_id = data.booth_pears_program_id;
    // } else if (data.event_type === 'CLASS') {
    //   data.pears_program_id = data.class_pears_program_id;
    // }
    // delete data.booth_pears_program_id;
    // delete data.class_pears_program_id;

    if (!data.curriculum_name) {
      delete data.curriculum_name;
    }
    if (!data.booth_topic_name) {
      delete data.booth_topic_name;
    }
    if (!data.lesson_name) {
      delete data.lesson_name;
    }

    if (!data.event_openness) {
      delete data.event_openness;
    } else {
      data.event_openness = data.event_openness === 'true';
    }

    if (data.state === undefined) {
      data.state = 'IL';
    }
    if (data.description === undefined) {
      data.description = 'Event description coming in a future phase.';
    }

    // Generate a unique short code for the event check-in process
    const shortCode = await generateUniqueShortCode();
    // Generate a QR code from the event short code
    const qrCode = await generateQRCode(shortCode);

    data = {
      ...data,
      event_start: notzUTCString(data.event_start),
      event_end: notzUTCString(data.event_end),
      start_date: data.event_start.format('Y-MM-DD'),
      start_time: notzUTCString(data.event_start),
      end_time: notzUTCString(data.event_end),
      short_code: shortCode,
      qr_code: qrCode,
    };

    try {
      await API.graphql({
        ...graphqlOperation(createEvent, { input: data }),
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      setPageMessage('Event Created');
      scrollToTitle()
      setSubmitting(false);
      resetForm();
    } catch (error) {
      console.log('Error creating event: ', error);
      setSubmitting(false);
      setPageErrorMessage('Error creating event. Please try again.');
      scrollToTitle();
    }

    // Sign out guest if using INEP link
    if (inep) {
      await signOutGuest();
    }
  };

  const handleEventTypeChange = async (value) => {
    if (value === 'BOOTH') {
      setValue('curriculum_name', '');
      setValue('lesson_name', '');
    } else {
      setValue('booth_topic_name', '');
    }
  };

  const handleCurriculumChange = async (value) => {
    setValue('lesson_name', '');
    const curriculumName = value;
    const lessons = availableCurriculum.filter((item) => item.curriculum_name === curriculumName);
    setLessons(lessons);
  };

  const handleLessonNameChange = async (value) => {
    const lesson_name = value;
    if (!lesson_name) {
      setValue('points', 0);
      // setValue('description', '');
      return;
    }
    const lesson = availableCurriculum.find((x) => x.curriculum_name === getValues('curriculum_name') && x.lesson_name === lesson_name);
    setValue('points', lesson.points);
    setValue('description', lesson.description);
  };

  const handleBoothTopicChange = async (value) => {
    const booth_topic = value;
    if (!booth_topic) {
      setValue('points', 0);
      // setValue('description', '');
      return;
    }
    const boothTopic = availableTopics.find((x) => x.booth_topic === booth_topic);
    setValue('points', boothTopic.points);
    setValue('description', boothTopic.description);
  };

  const getCurriculumOptions = (type) => {
    let items = [];
    if (type === 'curriculum' && availableCurriculum) {
      items = [...new Set(availableCurriculum.map((item) => item.curriculum_name))].sort();
    } else if (type === 'lesson' && lessons) {
      items = lessons.map((item) => item.lesson_name);
    } else if (type === 'booth' && availableTopics) {
      items = [...new Set(availableTopics.map((item) => item.booth_topic))];
    }
    return items.map((c) => ({ label: c, id: c }));
  };

  const isAdminOrINEP = inep || isAdmin();

  return (
    <Page
      title="Create Event"
      sectionName="events"
      navPhoto={{ image: '/images/events/CREATE_EVENT_PHOTO.jpg' }}
    >
      <Grid container gap={3} wrap={'nowrap'} columns={10}>
        <Grid xs={10}>
          <Box width={'100%'}>
            {!isAdminOrINEP && <Alert severity="error">Access denied</Alert>}
            {isAdminOrINEP && (
              <FormContainer onSuccess={handleSubmit(onSubmit)} FormProps={{ className: 'mui' }}>
                <Typography mt={'15px'} mb={'15px'} sx={{ fontWeight: 600 }} component="div">
                  <label className={'form-text required'}>Required Fields</label>
                </Typography>
                <Grid container columns={12} spacing={2}>
                  <Grid xs={12} sm={10} md={8}>
                    <SelectElement
                      name="internal_only"
                      label={'Is this event being created for internal training or test purposes?'}
                      placeholder={'Select event purpose'}
                      variant={'standard'}
                      control={control}
                      fullWidth
                      required
                      options={[
                        { label: 'Yes', id: 'true' },
                        { label: 'No', id: 'false' },
                      ]}
                    />
                  </Grid>
                  <Grid xs={12} sm={10} md={8}>
                    <SelectElement
                      name="has_wifi"
                      label={'Is WiFi available for you to use at this site?'}
                      placeholder={'Select WiFi availability'}
                      variant={'standard'}
                      control={control}
                      fullWidth
                      required
                      options={[
                        { label: 'Yes', id: 'Yes' },
                        { label: 'No', id: 'No' },
                        { label: 'I don\'t know', id: 'I don\'t know' },
                      ]}
                    />
                  </Grid>
                  <Grid xs={12} sm={10} md={8}>
                    <TextFieldElement
                      name="first_name"
                      label={'First Name'}
                      variant={'standard'}
                      control={control}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} sm={10} md={8}>
                    <TextFieldElement
                      name="last_name"
                      label={'Last Name'}
                      variant={'standard'}
                      control={control}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} sm={10} md={8}>
                    <TextFieldElement
                      name="email"
                      label={'Email'}
                      variant={'standard'}
                      type={'email'}
                      control={control}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} sm={10} md={8}>
                    <SelectElement
                      name="event_type"
                      label={'What type of event is this?'}
                      placeholder={'Select event type'}
                      variant={'standard'}
                      control={control}
                      onChange={handleEventTypeChange}
                      fullWidth
                      required
                      options={[
                        { label: 'Direct Education Class', id: 'CLASS' },
                        { label: 'Eat.Move.Save Booth', id: 'BOOTH' },
                      ]}
                    />
                  </Grid>
                  <Grid xs={12} sm={10} md={8}>
                    <SelectElement
                      name="event_openness"
                      label={'Is this event open to the public?'}
                      placeholder={'Select openness'}
                      variant={'standard'}
                      control={control}
                      fullWidth
                      required
                      options={[
                        { label: 'Yes', id: 'true' },
                        { label: 'No', id: 'false' },
                      ]}
                    />
                  </Grid>
                  <Grid xs={12} sm={10} md={8}>
                    <TextFieldElement
                      name="location_name"
                      type={'text'}
                      label={'Name of Event Location'}
                      variant={'standard'}
                      control={control}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid xs={12} sm={10} md={8}>
                    <TextFieldElement
                      name="street_address"
                      type={'text'}
                      label={'Street Address of Event'}
                      variant={'standard'}
                      control={control}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid xs={12} sm={10} md={8}>
                    <TextFieldElement
                      name="city"
                      label={'City/Town'}
                      variant={'standard'}
                      control={control}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} sm={7} md={6}>
                    <SelectElement
                      name="state"
                      label={'State'}
                      variant={'standard'}
                      control={control}
                      disabled
                      required
                      fullWidth
                      options={states.map(item => ({ id: item.abbreviation, label: item.name }))}
                    />
                  </Grid>
                  <Grid xs={12} sm={3} md={2}>
                    <TextFieldElement
                      name="zipcode"
                      label={'Zip Code'}
                      variant={'standard'}
                      control={control}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} sm={10} md={8}>
                    <TextFieldElement
                      name="county"
                      label={'County'}
                      variant={'standard'}
                      control={control}
                      required
                      fullWidth
                    />
                  </Grid>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid xs={12} sm={8} mt={'24px'}>
                      <DateTimePickerElement
                        name={'event_start'}
                        label={'Event Start Date & Time'}
                        helperText={'Start Date must be today or greater.'}
                        control={control}
                        disablePast
                        required
                        validation={{
                          validate: {
                            range: (value) => {
                              const endDate = getValues('event_end');
                              if (!value || !endDate) return true;
                              const valid = !value.isAfter(endDate);
                              return valid || 'Start Date & Time must be less than End Date & Time';
                            }
                          }
                        }}
                        onChange={(value) => {
                          if (!value) return true;
                          const eventEnd = getValues('event_end');
                          const newEnd = (eventEnd && eventEnd.isValid()) ? moment(eventEnd?.format()) : null;
                          if (newEnd && value && value.isValid()) {
                            newEnd.set({
                              year: value.get('year'),
                              month: value.get('month'),
                              date: value.get('date'),
                            }).format();
                            setValue('event_end', newEnd);
                          }
                          trigger('event_start').then();
                          trigger('event_end').then();
                        }}
                        inputProps={{
                          variant: 'standard',
                          fullWidth: true,
                        }}
                      />
                    </Grid>
                    <Grid xs={12} sm={10} md={8}>
                      <DateTimePickerElement
                        name={'event_end'}
                        label={'Event End Date & Time'}
                        helperText={'End Date & Time must be greater than Start Date & Time.'}
                        control={control}
                        required
                        disablePast
                        validation={{
                          validate: {
                            range: (value) => {
                              const eventStart = getValues('event_start');
                              if (!value || !eventStart) return true;
                              const valid = value.isAfter(eventStart);
                              return valid || 'End Date & Time must be greater than Start Date & Time';
                            }
                          }
                        }}
                        onChange={() => {
                          trigger('event_start').then();
                          trigger('event_end').then();
                        }}
                        inputProps={{
                          variant: 'standard',
                          fullWidth: true,
                        }}
                      />
                    </Grid>
                  </LocalizationProvider>
                  { ['CLASS', 'BOOTH'].includes(eventTypeWatch) && (
                    <Grid xs={12} mt={'24px'}>
                      <Typography variant="h6" fontWeight={600}>
                        FOR { eventTypeWatch === 'CLASS' ? 'DIRECT EDUCATION CLASSES' : 'EAT.MOVE.SAVE. BOOTH' } ONLY
                      </Typography>
                    </Grid>
                  )}
                  {eventTypeWatch === 'CLASS' && (
                    <>
                      <Grid xs={12} sm={10} md={8}>
                        <SelectElement
                          name="curriculum_name"
                          label={'Name of the Curriculum'}
                          placeholder={'Choose curriculum'}
                          variant={'standard'}
                          control={control}
                          onChange={handleCurriculumChange}
                          fullWidth
                          required
                          options={getCurriculumOptions('curriculum')}
                        />
                      </Grid>
                      <Grid xs={12} sm={10} md={8}>
                        <SelectElement
                          name="lesson_name"
                          label={'Name of the Lesson'}
                          placeholder={'Choose lesson'}
                          variant={'standard'}
                          control={control}
                          onChange={handleLessonNameChange}
                          fullWidth
                          required
                          options={getCurriculumOptions('lesson')}
                        />
                      </Grid>
                    </>
                  )}
                  {eventTypeWatch === 'BOOTH' && (
                    <Grid xs={12} sm={10} md={8}>
                      <SelectElement
                        name="booth_topic_name"
                        label={'Name of the Booth Topic'}
                        placeholder={'Choose booth topic'}
                        variant={'standard'}
                        control={control}
                        onChange={handleBoothTopicChange}
                        fullWidth
                        required
                        options={getCurriculumOptions('booth')}
                      />
                    </Grid>
                  )}
                  <Grid xs={12} sm={10} md={8}>
                    <TextFieldElement
                      name="description"
                      label={'Event Description'}
                      variant={'standard'}
                      control={control}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid xs={12} sm={10} md={8}>
                    <Button
                      disabled={submitting}
                      type={'submit'}
                      variant="contained"
                      size="small"
                      sx={{ marginTop: '20px' }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </FormContainer>
            )}
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export default CreateEvent;
