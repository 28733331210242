/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLessonSeries = /* GraphQL */ `
  query GetLessonSeries($id: ID!) {
    getLessonSeries(id: $id) {
      id
      curriculum_name
      lesson_name
      booth_topic
      snap_ed
      efnep
      cphp
      points
      description
      display_order
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLessonSeries = /* GraphQL */ `
  query ListLessonSeries(
    $filter: ModelLessonSeriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessonSeries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        curriculum_name
        lesson_name
        booth_topic
        snap_ed
        efnep
        cphp
        points
        description
        display_order
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lessonsByCurriculumName = /* GraphQL */ `
  query LessonsByCurriculumName(
    $curriculum_name: String!
    $lesson_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLessonSeriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lessonsByCurriculumName(
      curriculum_name: $curriculum_name
      lesson_name: $lesson_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        curriculum_name
        lesson_name
        booth_topic
        snap_ed
        efnep
        cphp
        points
        description
        display_order
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      title
      description
      points
      activity_code
      users {
        items {
          id
          activityID
          userID
          activity {
            id
            title
            description
            points
            activity_code
            createdAt
            updatedAt
            owner
            __typename
          }
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        points
        activity_code
        users {
          items {
            id
            activityID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      description
      start_date
      start_time
      end_time
      event_start
      event_end
      duration_in_seconds
      points
      internal_only
      event_type
      phone
      street_address
      zipcode
      city
      county
      state
      booth_topic_name
      curriculum_name
      lesson_name
      first_name
      middle_name
      last_name
      email
      event_openness
      location_name
      pears_program_id
      short_code
      checked_in_users {
        items {
          id
          eventID
          userID
          event {
            id
            description
            start_date
            start_time
            end_time
            event_start
            event_end
            duration_in_seconds
            points
            internal_only
            event_type
            phone
            street_address
            zipcode
            city
            county
            state
            booth_topic_name
            curriculum_name
            lesson_name
            first_name
            middle_name
            last_name
            email
            event_openness
            location_name
            pears_program_id
            short_code
            qr_code
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      qr_code
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        start_date
        start_time
        end_time
        event_start
        event_end
        duration_in_seconds
        points
        internal_only
        event_type
        phone
        street_address
        zipcode
        city
        county
        state
        booth_topic_name
        curriculum_name
        lesson_name
        first_name
        middle_name
        last_name
        email
        event_openness
        location_name
        pears_program_id
        short_code
        checked_in_users {
          items {
            id
            eventID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qr_code
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventByShortCode = /* GraphQL */ `
  query EventByShortCode(
    $short_code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByShortCode(
      short_code: $short_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        start_date
        start_time
        end_time
        event_start
        event_end
        duration_in_seconds
        points
        internal_only
        event_type
        phone
        street_address
        zipcode
        city
        county
        state
        booth_topic_name
        curriculum_name
        lesson_name
        first_name
        middle_name
        last_name
        email
        event_openness
        location_name
        pears_program_id
        short_code
        checked_in_users {
          items {
            id
            eventID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qr_code
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userpool_user_id
      email
      phone_number
      street_address
      city
      county
      race
      ethnicity
      gender
      age_range
      zip_code
      household_size
      allow_cellphone
      allow_notification
      allow_enews
      allow_text_program
      is_participating
      is_eligible
      first_name
      last_name
      full_name
      state
      birth_year
      points {
        items {
          id
          points
          type
          userID
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      events_attended {
        items {
          id
          eventID
          userID
          event {
            id
            description
            start_date
            start_time
            end_time
            event_start
            event_end
            duration_in_seconds
            points
            internal_only
            event_type
            phone
            street_address
            zipcode
            city
            county
            state
            booth_topic_name
            curriculum_name
            lesson_name
            first_name
            middle_name
            last_name
            email
            event_openness
            location_name
            pears_program_id
            short_code
            qr_code
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          activityID
          userID
          activity {
            id
            title
            description
            points
            activity_code
            createdAt
            updatedAt
            owner
            __typename
          }
          user {
            id
            userpool_user_id
            email
            phone_number
            street_address
            city
            county
            race
            ethnicity
            gender
            age_range
            zip_code
            household_size
            allow_cellphone
            allow_notification
            allow_enews
            allow_text_program
            is_participating
            is_eligible
            first_name
            last_name
            full_name
            state
            birth_year
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        points {
          items {
            id
            points
            type
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events_attended {
          items {
            id
            eventID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        activities {
          items {
            id
            activityID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByUserpoolUserID = /* GraphQL */ `
  query GetUserByUserpoolUserID(
    $userpool_user_id: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByUserpoolUserID(
      userpool_user_id: $userpool_user_id
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        points {
          items {
            id
            points
            type
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events_attended {
          items {
            id
            eventID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        activities {
          items {
            id
            activityID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserScore = /* GraphQL */ `
  query GetUserScore($id: ID!) {
    getUserScore(id: $id) {
      id
      points
      type
      userID
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        points {
          items {
            id
            points
            type
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events_attended {
          items {
            id
            eventID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        activities {
          items {
            id
            activityID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserScores = /* GraphQL */ `
  query ListUserScores(
    $filter: ModelUserScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        points
        type
        userID
        user {
          id
          userpool_user_id
          email
          phone_number
          street_address
          city
          county
          race
          ethnicity
          gender
          age_range
          zip_code
          household_size
          allow_cellphone
          allow_notification
          allow_enews
          allow_text_program
          is_participating
          is_eligible
          first_name
          last_name
          full_name
          state
          birth_year
          points {
            nextToken
            __typename
          }
          events_attended {
            nextToken
            __typename
          }
          activities {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const scoreByUser = /* GraphQL */ `
  query ScoreByUser(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scoreByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        points
        type
        userID
        user {
          id
          userpool_user_id
          email
          phone_number
          street_address
          city
          county
          race
          ethnicity
          gender
          age_range
          zip_code
          household_size
          allow_cellphone
          allow_notification
          allow_enews
          allow_text_program
          is_participating
          is_eligible
          first_name
          last_name
          full_name
          state
          birth_year
          points {
            nextToken
            __typename
          }
          events_attended {
            nextToken
            __typename
          }
          activities {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserActivities = /* GraphQL */ `
  query GetUserActivities($id: ID!) {
    getUserActivities(id: $id) {
      id
      activityID
      userID
      activity {
        id
        title
        description
        points
        activity_code
        users {
          items {
            id
            activityID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        points {
          items {
            id
            points
            type
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events_attended {
          items {
            id
            eventID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        activities {
          items {
            id
            activityID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserActivities = /* GraphQL */ `
  query ListUserActivities(
    $filter: ModelUserActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activityID
        userID
        activity {
          id
          title
          description
          points
          activity_code
          users {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        user {
          id
          userpool_user_id
          email
          phone_number
          street_address
          city
          county
          race
          ethnicity
          gender
          age_range
          zip_code
          household_size
          allow_cellphone
          allow_notification
          allow_enews
          allow_text_program
          is_participating
          is_eligible
          first_name
          last_name
          full_name
          state
          birth_year
          points {
            nextToken
            __typename
          }
          events_attended {
            nextToken
            __typename
          }
          activities {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserEvents = /* GraphQL */ `
  query GetUserEvents($id: ID!) {
    getUserEvents(id: $id) {
      id
      eventID
      userID
      event {
        id
        description
        start_date
        start_time
        end_time
        event_start
        event_end
        duration_in_seconds
        points
        internal_only
        event_type
        phone
        street_address
        zipcode
        city
        county
        state
        booth_topic_name
        curriculum_name
        lesson_name
        first_name
        middle_name
        last_name
        email
        event_openness
        location_name
        pears_program_id
        short_code
        checked_in_users {
          items {
            id
            eventID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        qr_code
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        points {
          items {
            id
            points
            type
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        events_attended {
          items {
            id
            eventID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        activities {
          items {
            id
            activityID
            userID
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserEvents = /* GraphQL */ `
  query ListUserEvents(
    $filter: ModelUserEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        userID
        event {
          id
          description
          start_date
          start_time
          end_time
          event_start
          event_end
          duration_in_seconds
          points
          internal_only
          event_type
          phone
          street_address
          zipcode
          city
          county
          state
          booth_topic_name
          curriculum_name
          lesson_name
          first_name
          middle_name
          last_name
          email
          event_openness
          location_name
          pears_program_id
          short_code
          checked_in_users {
            nextToken
            __typename
          }
          qr_code
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          userpool_user_id
          email
          phone_number
          street_address
          city
          county
          race
          ethnicity
          gender
          age_range
          zip_code
          household_size
          allow_cellphone
          allow_notification
          allow_enews
          allow_text_program
          is_participating
          is_eligible
          first_name
          last_name
          full_name
          state
          birth_year
          points {
            nextToken
            __typename
          }
          events_attended {
            nextToken
            __typename
          }
          activities {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
