import QRCode from 'qrcode';
import scrollIntoView from 'scroll-into-view';

/**
 * Format's a moment date/time into ISO standard as UTC without the conversion so that the
 * local time is pretended to be UTC all along.
 * @param m
 * @returns {string}
 */
export const notzUTCString = (m) => m.format('YYYY-MM-DDTHH:mm:ss.sss') + 'Z';

export const scrollToTitle = () => {
  setTimeout(() => {
    let elems = document.getElementsByClassName('page-title');
    if (elems.length > 0) {
      scrollIntoView(elems[0], { align: { top: 0 }});
    } else {
      window.scrollTo({ top: 200, left: 0, behavior: 'smooth' });
    }
  }, 100);
};

export const scrollToAlert = () => {
  setTimeout(() => {
    let e = document.getElementsByClassName('MuiAlert-root')[0];
    if (e) {
      scrollIntoView(e, { align: { top: 0 }});
    } else {
      scrollToTitle();
    }
  }, 100);
};

export const tenDigit = (phone) => {
  return phone.replace(/\+1/, '').replaceAll(/[^0-9]/g, '');
};

export const asYouTypePhone = (input, phoneNumber) => {
  const deleted = input.length < phoneNumber.length;
  if (input.length > 14) {
    return phoneNumber;
  }
  const chars = input.replaceAll(/[^() \-0-9]/g, '');
  let result = '';
  for (let i = 0; i < chars.length; i++) {
    const digit = chars[i];
    const lastDigit = result.length === chars.length - 1;
    const last2Digit = result.length === chars.length - 2;
    // console.log('i:', i, 'digit: ', digit, ' result len: ', result.length, 'last digit: ', lastDigit);
    const isNumeric = (val) => (val.length && !/[^0-9]/.test(val));
    const allowed = {
      0: /[(0-9]/g,
      4: /[)0-9]/g,
      5: /[ 0-9]/g,
      9: /[-0-9]/g,
    };
    if (i === 0 && digit === '1') {
      continue;
    }
    switch (result.length) {
      case 0:
        if (allowed[0].test(digit)) {
          if (isNumeric(digit)) {
            result += '(';
          }
          if (!deleted || !lastDigit) {
            result += digit;
          }
        }
        break;
      case 4:
        if (allowed[4].test(digit)) {
          if (isNumeric(digit)) {
            result += ') ';
          }
          if (!deleted || (!lastDigit && !last2Digit)) {
            result += digit;
          }
        }
        break;
      case 5:
        if (allowed[5].test(digit)) {
          if (isNumeric(digit)) {
            result += ' ';
          }
          if (!lastDigit) {
            result += digit;
          }
        }
        break;
      case 9:
        if (allowed[9].test(digit)) {
          if (isNumeric(digit)) {
            result += '-';
          }
          if (!deleted || !lastDigit) {
            result += digit;
          }
        }
        break;
      default:
        if (isNumeric(digit) && result.length < 14) {
          result += digit;
        }
        break;
    }
  }
  return result;
};

export const paletteMains = (theme) => Object.keys(theme.palette).reduce((acc, key) => {
  acc[key] = theme.palette[key].main;
  return acc;
}, {});

export const generateQRCode = async (text) => {
  try {
    return await QRCode.toDataURL(text);
  } catch (err) {
    console.error('QR Code generation error', err);
  }
};

export const generateShortCode = () => {
  const length = 8;
  const chars = '134679ACDEFHIJKLMNPQRTUVWXY';
  let shortCode = '';
  for (var i = length; i > 0; --i) {
    shortCode += chars[Math.floor(Math.random() * chars.length)];
  }
  return shortCode;
};
