import {API, graphqlOperation} from 'aws-amplify';

const runQuery = async (query, variables) => {
  try {
    return API.graphql({ ...graphqlOperation(query, variables), authMode: 'API_KEY' });
  } catch (error) {
    return {
      errors: [
        error
      ]
    };
  }
};

const getAllCurriculums = async () => {
  const query = /* GraphQL */`
    query getAllCurriculum($filter: ModelLessonSeriesFilterInput, $limit: Int = 1000) {
      listLessonSeries(filter: $filter, limit: $limit) {
        items {
          curriculum_name
          lesson_name
          description
          points
          display_order
        }
      }
    }
  `;

  const variables = {
    filter: {
      curriculum_name: {
        attributeExists: true,
      }
    }
  };

  const response = await runQuery(query, variables);
  const curriculums = response.data.listLessonSeries.items;
  curriculums.sort((a, b) => {
    return parseInt(a?.display_order ?? 99999) - parseInt(b?.display_order ?? 99999);
  });

  return curriculums;
};

const getBoothTopics = async () => {
  const query = /* GraphQL */`
    query getBoothTopics($filter: ModelLessonSeriesFilterInput, $limit: Int = 1000) {
      listLessonSeries(filter: $filter, limit: $limit) {
        items {
          booth_topic
          description
          points
          display_order
        }
      }
    }
  `;

  let variables = {
    filter: {
      and: [
        { booth_topic: { attributeExists: true } },
        { booth_topic: { gt: '' } },
      ]
    }
  };

  const response = await runQuery(query, variables);
  const boothTopics = response.data.listLessonSeries.items;
  boothTopics.sort((a, b) => {
    return parseInt(a?.display_order ?? 99999) - parseInt(b?.display_order ?? 99999);
  });

  return boothTopics;
};

export {getAllCurriculums, getBoothTopics};
