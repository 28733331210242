const getCurrentPageSettings = (location) => {
  let pathArray = []
  if (location.pathname === "/") {
    pathArray[0] = '0'
    pathArray[1] = 'home'
  } else {
    pathArray = location.pathname.split("/")
    pathArray[0] = (pathArray.length - 1).toString()
  }

  // special case for INEP Create Event
  if (location.pathname.includes('INEP=')) {
    pathArray[0] = 2;
    pathArray[1] = 'events';
    pathArray[2] = 'create';
  }

  const pageName = pathArray[pathArray.length - 1];
  const pageNameCap = `${pageName.substring(0, 1).toUpperCase()}${pageName.substring(1)}`;

  return (
    {
      "initLoad": false,
      "sectionName": pathArray[1],
      "level": pathArray[0],
      pageName,
      pageNameCap
    }
  )
}

export {
  getCurrentPageSettings
}
