import {createContext, useCallback, useRef, useState} from 'react';

import {API, Auth, graphqlOperation} from 'aws-amplify';
import {getUserByUserpoolUserID} from '../queries';
import {useNavigate} from 'react-router-dom';

export const UserContext = createContext({});

const getUserRecord = async (userpool_user_id) => {
  try {
    const response = await API.graphql(graphqlOperation(
      getUserByUserpoolUserID,
      { userpool_user_id: userpool_user_id }
    ));
    return response.data.getUserByUserpoolUserID.items[0];
  } catch (error) {
    console.log('Error: ', error);
  }
};

export const UserProvider = (props) => {
  const storedUserJson = sessionStorage.getItem('user');
  const storedUser = JSON.parse(!!storedUserJson ? storedUserJson : '{}');
  const storedUserRecordJson = sessionStorage.getItem('userRecord') || '{}';
  const storedUserRecord = !!storedUserRecordJson ? JSON.parse(storedUserRecordJson) : {};
  const [userState, setUserState] = useState({
    session: storedUser || {},
    userRecord: storedUserRecord || {}
  });
  const [loadingRecord, setLoadingRecord] = useState(false);

  const navigate = useNavigate();

  // Note: Had to use refs so that calls to isAdmin and isUser would reflect immediate changes after sign in.
  const userSessionRef = useRef(storedUser || {});
  const userRecordRef = useRef(storedUserRecord || {});

  const updateState = (session, record) => {
    userSessionRef.current = session;
    userRecordRef.current = record;
    setUserState({ session, userRecord: record });
    sessionStorage.setItem('user', session ? JSON.stringify(session) : null);
    sessionStorage.setItem('userRecord', record ? JSON.stringify(record) : null);
  };

  const isLoggedIn = () => {
    return !!userState.session?.signInUserSession && !!userState.userRecord?.id;
  };

  // Log in the guest user so inep end user can create events
  const signInGuest = async () => {
    try {
      await Auth.signIn({
        username: process.env.REACT_APP_GUEST_USER_EMAIL,
        password: process.env.REACT_APP_GUEST_USER_PASSWORD,
      });
    } catch (error) {
      console.log('Temporary sign in failed.', error);
      navigate('/login');
    }
  }

  const signOutGuest = async () => {
    try {
      await Auth.signOut();
      sessionStorage.clear();
    } catch (err) {
      console.log('Error signing out guest user...');
    }
  };

  const loadUserRecord = useCallback((userSession) => {
    // console.log('[userContext] Loading user record...', userSession);
    setLoadingRecord(true);
    return getUserRecord(userSession.username).then((cognitoUserRecord) => {
      updateState(userSession, cognitoUserRecord);
    }).catch((err) => {
      console.error(err);
      updateState(userSessionRef.current, null);
    }).finally(() => {
      setLoadingRecord(false);
    });
  }, [userState]);

  const signIn = async (email, password) => {
    const session = await Auth.signIn(email, password);
    await loadUserRecord(session);
    return session;
  };

  const signOut = async () => {
    await Auth.signOut({ global: true });
    navigate('/');
    updateState(null, null);
  };

  const reloadUserRecord = () => {
    if (userState.session?.id) {
      return loadUserRecord(userState.session);
    }
    return Promise.reject('No user record');
  };

  const updateUserRecord = (record) => {
    updateState(userSessionRef.current, record);
  };

  const isUser = () => {
    const groups = userSessionRef.current?.signInUserSession?.accessToken.payload["cognito:groups"] || [];
    return groups.includes('Users');
  };

  const isAdmin = () => {
    const groups = userSessionRef.current?.signInUserSession?.accessToken.payload["cognito:groups"] || [];
    return groups.includes('Educators');
  };

  return (
    <UserContext.Provider value={{
      userState,
      setUserState,
      updateUserRecord,
      reloadUserRecord,
      signIn,
      signOut,
      signInGuest,
      signOutGuest,
      isUser,
      isAdmin,
      isLoggedIn,
    }}>
      { !loadingRecord &&
        <>{ props.children }</>
      }
    </UserContext.Provider>
  );
};

export default UserContext;
