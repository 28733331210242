import React, {useCallback, useEffect, useState} from 'react';
import {API, graphqlOperation} from 'aws-amplify';
import {getEvent} from '../../../graphql/queries';
import {useLocation} from 'react-router-dom';
import Page from '../Page';


const EventDetails = () => {
  const [event, setEvent] = useState('');

  const fetchEvent = useCallback(async (eventID) => {
    try {
      if (!eventID) {
        return;
      }
      const result = await API.graphql({
        ...graphqlOperation(getEvent, { id: eventID }),
        authMode: 'API_KEY'
      });
      return result.data.getEvent;
    } catch (error) {
      console.log('Error: ', error);
      setEvent(null);
    }
  }, []);

  const queryString = new URLSearchParams(useLocation().search).get('eventID');

  useEffect(() => {
    fetchEvent(queryString).then((data) => {
      setEvent(data);
    });
  }, [fetchEvent, queryString]);

  const title = event === null ? 'Event not found' : event?.lesson_name || event?.booth_topic_name;

  return (
    <>
      <Page
        title={title || 'Event Details'}
        navPhoto={{ image: '/images/activities/left_sidebar.jpg' }}
      >
        <div id="pagewrapper" className={'with-two-sidebar'} style={{ padding: 0 }}>
          <div id="content-wrapper" className={'event-details-card'}>
            {event && event !== '' &&
              <>
                <div className={'event-datetime row row1'}>
                  <span>{new Date(event.start_date).toLocaleDateString('en-us', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}</span>
                  <span> &nbsp;|&nbsp; </span>
                  <span>{new Date(event.start_time).toLocaleString('en-us', { timeStyle: 'short' })}</span>
                  <span> - </span>
                  <span>{new Date(event.end_time).toLocaleString('en-us', { timeStyle: 'short' })}</span>
                </div>
                <div className={'location row row2'}>
                  <div className={'map-pin-container'}>
                    <div className={'map-pin'}></div>
                  </div>
                  <span>{event.location_name}</span>,&nbsp;
                  <span>{event.street_address}</span>,&nbsp;
                  <span>{event.city}</span>,&nbsp;
                  <span>{event.state}</span>
                </div>
                <div className={'points-value row row3'}>
                  <span className={'label'}>POINT VALUE: </span>
                  <span>{event.points} </span>
                </div>
                <div className={'field-description row row4'}>
                  <div>
                    {event.description}
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </Page>
    </>
  );
};

export default EventDetails;
