import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import UserContext from '../../lib/userContext';
import { useContext } from 'react';

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  const { userState: { session: userSession } } = useContext(UserContext);

  if (route !== 'authenticated' && !userSession?.signInUserSession) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export default RequireAuth;
