import React, {useContext} from 'react';
import NavDrawer from './components/common/Layout/NavDrawer';
import PageRoutes from './components/Pages/PageRoutes';
import Footer from './components/common/Layout/Footer';
import Header from './components/common/Layout/Header';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import colors from './sass/src_mui/_init/_variables.scss';

// AMPLIFY
import awsExports from './aws-exports.js';
import {Amplify} from '@aws-amplify/core';
import {PageContext} from './lib/pageContext';
import CssBaseline from '@mui/material/CssBaseline';
import { ConfirmProvider } from 'material-ui-confirm';

Amplify.configure(awsExports);
const App = () => {
  const { pageSettings: { sectionName, level } } = useContext(PageContext);

  const colorPalette = Object.keys(colors).reduce((acc, key) => {
    acc[key] = { main: colors[key] };
    return acc;
  }, {
    primary: { main: colors['il-industrial'] },
    secondary: { main: colors['il-blue'] },
    hover: { main: colors['il-altgeld'] },
    text: { primary: colors['il-storm'] },
    ilblue: { main: colors['il-blue'] },
    ilorange: { main: colors['il-orange'] },
    ilaltgeld: { main: colors['il-altgeld'] },
    ilalma: { main: colors['il-alma'] },
    ilstorm: { main: colors['il-storm'] },
    ilindustrial: { main: colors['il-industrial'] },
  });

  let theme = createTheme({
    typography: {
      fontFamily: 'SourceSans',
      fontSize: 14,
      htmlFontSize: 16,
      h1: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
      },
      h2: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
      },
      h3: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
      },
      h4: {
        fontFamily: 'Montserrat',
        fontSize: '32px',
        marginBottom: '21px',
        fontWeight: 700,
        color: colorPalette.ilblue.main,
      },
      h5: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
      },
      h6: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        color: colorPalette.ilblue.main,
      },
      h7: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '18px',
      }
    },
    palette: colorPalette,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        sd: 760,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            textDecorationColor: colorPalette.ilindustrial.main,
            '&:hover': {
              cursor: 'pointer',
              color: colorPalette.hover.main,
              transition: 'color 0.3s',
            }
          }
        },
        defaultProps: {
          underline: 'always',
        },
        variants: [
          {
            props: { variant: 'footer' },
            style: {
              fontFamily: 'SourceSans',
              color: '#fff',
              textDecorationColor: '#fff',
              '&:hover': {
                color: colorPalette.ilorange.main,
              }
            }
          }
        ]
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#000',
            fontWeight: 600,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            '&:not(.MuiPickersToolbar-root)': {
              fontSize: '1rem',
              fontFamily: 'SourceSans',
              textTransform: 'none',
              borderRadius: '5px',
              padding: '0.25rem 1.3rem',
              boxShadow: 'unset',

              '&:not(.MuiButton-text):not(.MuiButton-outlined)': {
                background: colorPalette.ilblue.main,
                color: '#ffffff',
                border: `1px solid ${colorPalette.ilblue.main}`,

                '&:hover, &:focus': {
                  color: colorPalette.ilblue.main,
                  background: '#fff',
                  border: `1px solid ${colorPalette.ilblue.main}`
                }
              },
              '&.MuiButton-text': {
                color: colorPalette.ilblue.main,

                '&:hover, &:focus': {
                  background: `${colorPalette.ilblue.main}25`,
                },
              },
              '&.MuiButton-outlined': {
                color: colorPalette.ilblue.main,
                border: `1px solid ${colorPalette.ilblue.main}40`,

                '&:hover, &:focus': {
                  background: `${colorPalette.ilblue.main}15`,
                  border: `1px solid ${colorPalette.ilblue.main}60`,
                },
              },
            },
          },
          contained: {
          }
        },
        variants: [
          {
            props: { size: 'extraSmall' },
            style: { fontSize: '0.7rem', padding: '2px 1.5rem' }
          }
        ]
      },
      MuiListItemButton: {
        defaultProps: {
          disableTouchRipple: true,
        },
      },
      MuiDateTimePickerToolbar: {
        styleOverrides: {
          timeContainer: {
            alignSelf: 'end',
            '& .MuiPickersToolbarText-root.MuiTypography-h3': {
              lineHeight: 1.1,
            }
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            background: '#fff',
          }
        }
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: colorPalette.ilstorm.main,
          }
        }
      }
    },
  });

  theme = createTheme(theme, {
    components: {
      MuiList: {
        variants: [
          {
            props: { variant: 'nav' },
            style: {
              borderLeftColor: 'transparent',
              '& a': {
                padding: '4px 16px',
                '& .MuiListItemText-root': {
                  marginTop: 0,
                  marginBottom: 0,
                },
                '&:hover': {
                  '.MuiListItemText-root span': {
                    color: colorPalette.ilaltgeld.main,
                  }
                },
                '& .MuiListItemText-root span': {
                  color: colorPalette.ilblue.main,
                  fontFamily: 'Montserrat',
                  fontWeight: 600,
                  fontSize: theme.typography.pxToRem(16),
                  [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.pxToRem(20),
                  },
                },
              },
            }
          }
        ],
      },
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavDrawer />
      <ConfirmProvider>
        <div id="layout-container" className={'section-' + sectionName + ' lvl-' + level} data-section-name={sectionName}> {/*used for the expanding mobile menu*/}
          <Header/>
          <main className={'bodywrapper'}>
            <PageRoutes />
          </main>
        </div>
        <Footer/>
      </ConfirmProvider>
    </ThemeProvider>
  );
};

export default App;
