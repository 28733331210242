import React, {useContext, useEffect} from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import {Button, Paper} from '@mui/material';
import {Box} from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import Page from '../Page';
import {PageContext} from '../../../lib/pageContext';
import UserContext from '../../../lib/userContext';

const Login = () => {
  const { isLoggedIn, isAdmin } = useContext(UserContext);
  const { pageSettings: { pageName } } = useContext(PageContext);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) return;
    const redirect = searchParams.get('redirect');
    if (redirect) {
      navigate(redirect);
    } else if (isAdmin()) {
      navigate('/member/profile');
    } else {
      navigate('/member/activities');
    }
  });

  let title = 'Login';
  switch (pageName) {
    case 'password':
      title = 'Reset your password';
      break;
    case 'verify':
      title = 'Verification';
      break;
    default:
      break;
  }

  return (
    <Page
      title={title}
      hideNav
    >
      <Grid container gap={3} wrap={'nowrap'} className={'content-container'} columns={{ xs: 12, sm: 12, md: 9, lg: 10 }}>
        <Grid xs={12} sm={12} md={6} lg={8}>
          <Paper sx={{ padding: '50px 20px' }} variant="outlined">
            <Grid container columns={12}>
              <Grid xs={0} sm={2}></Grid>
              <Grid xs={12}>
                <Outlet/>
              </Grid>
            </Grid>
          </Paper>

          {pageName === 'login' && (
            <Box display="flex" justifyContent={'center'} width="100%" mt="50px">
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  navigate('/signup');
                }}
              >
                Sign Up
              </Button>
            </Box>
          )}
        </Grid>
        { /** This is a placeholder to maintain the right side width as equal to the left nav **/}
        <Box
          component={Grid}
          xs={0} sm={0} md={3} lg={2}
          display={{ xs: 'none', sm: 'none', md: 'block' }}
        />
      </Grid>
    </Page>
  );
};

export default Login;
