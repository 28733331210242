import React, {useContext} from 'react';
import {Stack} from '@mui/system';
import { useTheme } from '@mui/material';
import NavMenu from './NavMenu';
import UserContext from '../../../lib/userContext';

const Sidebar = ({ photo, nav = true }) => {
  const { isLoggedIn } = useContext(UserContext);
  const { image = null, alt = 'another smart thing' } = photo || {};

  const theme = useTheme();

  const listStyles = {
    borderLeft: `1px solid ${theme.palette.ilorange.main}`,
    '& a .MuiListItemText-root': {
      marginBottom: '2px',
      marginTop: '2px',
      '& span': {
        fontSize: theme.typography.pxToRem(14),
      }
    },
  };

  const showNav = nav && isLoggedIn();

  return (
    <aside id="left-sidebar" className={'sidebar'}>
      <Stack display={{ xs: 'none', sm: 'block' }}>
        {image && (
          <img src={image} alt={alt} style={{ marginBottom: '16px', maxWidth: '100%' }} />
        )}
        {showNav && (
          <NavMenu listStyles={listStyles} />
        )}
      </Stack>
    </aside>
  );
};

export default Sidebar;
