import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router} from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { UserProvider } from './lib/userContext';
import { PageProvider } from './lib/pageContext';
import App from './App'
import ScrollToTop from './components/common/ScrollToTop';
import './css/index.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <Authenticator.Provider>
        <UserProvider>
          <PageProvider>
            <App />
          </PageProvider>
        </UserProvider>
      </Authenticator.Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
