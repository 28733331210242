import React from 'react';
import SignUpForm from './SignUpForm';
import photo from '../../../sass/images/signup/photo.jpg';
import Page from '../Page';

const SignUp = () => {
  return (
    <Page
      title="Sign Up Form"
      navPhoto={{ image: photo }}
      rightSidebar
      divider
    >
      <SignUpForm />
    </Page>
  )
}

export default SignUp;
