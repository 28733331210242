import axios from 'axios';

const RELAY_URL = process.env.REACT_APP_RELAY_URL;
const RELAY_KEY = process.env.REACT_APP_RELAY_KEY;

const invokeRelay = async (payload) => axios.post(RELAY_URL, payload, {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + RELAY_KEY,
  },
});

/**
 * Add contact to a specific group (county) list.
 * @param group (county)
 * @param phone
 * @param firstName
 * @param lastName
 * @param email
 * @return response
 */
export const simpTextAddContact = async ({ group, phone, firstName, lastName, email }) => {
  const params = {
    token: process.env.REACT_APP_SIMPLETEXTING_KEY,
    group,
    phone,
    firstName,
    lastName,
    email,
    // comment: 'SOME_STRING_VALUE',
    // birthday: 'SOME_STRING_VALUE',
    // customField: 'SOME_STRING_VALUE'
  };
  const url = 'https://app2.simpletexting.com/v1/group/contact/add';
  return invokeRelay({
    url,
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    },
    body: {},
  });
};

/**
 * Remove contact from the UNSUBSCRIBE list (done when adding contacts).
 */
export const simpTextRemoveUnsub = ({ phone }) => {
  const params = {
    token: process.env.REACT_APP_SIMPLETEXTING_KEY,
    phone,
  };
  const url = 'https://app2.simpletexting.com/v1/contacts/blocklist/remove';
  return invokeRelay({
    url,
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    },
    body: {},
  });
};

/**
 * Add contact to the UNSUBSCRIBE list (removes contact).
 */
export const simpTextAddUnsub = ({ phone }) => {
  const params = {
    token: process.env.REACT_APP_SIMPLETEXTING_KEY,
    phone,
  };
  const url = 'https://app2.simpletexting.com/v1/contacts/blocklist/add';
  return invokeRelay({
    url,
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json',
    },
    body: {},
  });
};

